/* This example requires Tailwind CSS v2.0+ */
import React, { useContext } from "react"
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline'
import {GlobalDispatchContext, GlobalStateContext} from '../../context/GlobalContextProvider'
import { Link } from "gatsby"


export default function Banner() {
    const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  return (
    <>

      {/*
        Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
        your content from being obscured when the user scrolls to the bottom of the page.
      */}

{state.banner &&
      <div className="fixed inset-x-0 bottom-0">
        <div className="bg-indigo-600">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-indigo-800 md:block">
                  <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">Kostenlos für gemeinnützige Organisationen</span>
                  <span className="hidden md:inline">{state.banner}Alle unsere Dienstleistungen kostenlos für gemeinnützige Organisationen.</span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <Link
                onClick={() => {
                    dispatch({ type: "TOGGLE_BANNER" })
                  }}
                  to="/ehrenamt"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
                >
                  mehr erfahren
                </Link>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" onClick={() => {
            dispatch({ type: "TOGGLE_BANNER" })
          }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
}
    </>
  )
}
