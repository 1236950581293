import React from "react"
import Header from "../components/header"
import Footer from "./footer"
import Banner from "../components/general/banner"
import "../styles/global.css"

import {Helmet} from "react-helmet"

export default function Layout(props) {

    return (
        <>
        <Helmet
  htmlAttributes={{
    lang: 'de',
  }}
/>
    <div className="relative bg-gray-50 overflow-hidden">
        <Header noHeader={props.noHeader ? true : false} />
      {props.children}
      <Footer />
    </div>
    <Banner />
    </>
  )
}
